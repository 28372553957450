import './Timer.css';
import { useState ,useEffect} from 'react';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setTime } from '../../redux/slice/timerSlice';

export default function Timer() {
    const dispatch = useDispatch();
    const time = useSelector(state => state.timer.time);
    const [isVisible, setIsVisible] = useState(true);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    
    useEffect(() => {
        const timerId = setInterval(() => {
           dispatch(setTime(time -1));
        }, 1000);

        return () => clearInterval(timerId);
    }, [time,dispatch]);

    return (
        <div>

            <div className="countdown-timer">
                {isVisible ? 
                (<p className="timer m-0">{formatTime(time)}</p>)
                    : 
                   ( <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alarm" viewBox="0 0 16 16">
                        <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9z" />
                        <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1zm1.038 3.018a6 6 0 0 1 .924 0 6 6 0 1 1-.924 0M0 3.5c0 .753.333 1.429.86 1.887A8.04 8.04 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5M13.5 1c-.753 0-1.429.333-1.887.86a8.04 8.04 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1" />
                    </svg>)
                }

                <div className="py-2"></div>
            </div>



            <button
                className="mb-3 btn py-0 medium rounded-pill"
                style={{ border: "1px solid black" }}
                onClick={toggleVisibility}
            >
                {isVisible ? 'Hide' : 'Show'}
            </button>
        </div>
    );
}
