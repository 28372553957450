// src/pages/TestCompletion/TestCompletion.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TestCompletion.css'; // Optional: Custom CSS for additional styling

const TestCompletion = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/'); // Redirect to the homepage or another page
    };

    return (
        <div className="completion-container d-flex align-items-center justify-content-center vh-100">
            <div className="card text-center shadow-lg p-4">
                <div className="card-body">
                    <h2 className="card-title text-success mb-4">🎉 Chúc mừng bạn đã hoàn thành bài test đầu vào! 🎉</h2>
                    <p className="card-text lead">
                        Kết quả sẽ được thông báo tới bạn sau. Cảm ơn bạn đã tham gia!
                    </p>
                    <button className="btn btn-primary mt-3" onClick={handleGoHome}>
                        Quay lại Trang chủ
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TestCompletion;
