// src/components/Layout.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Layout = ({ handleLogout, children }) => {
  const location = useLocation();
  const accessToken = localStorage.getItem('accessToken');
  const userRoles = useSelector((state) => state.user.roles);
  const isAdmin = userRoles.some((role) => role.name === 'TEACHER');

  // Define routes where the navbar should be hidden
  const hideNavbarRoutes = ['/mock'];
  const shouldHideNavbar = hideNavbarRoutes.some(route => location.pathname.startsWith(route));

  return (
    <div>
      {/* Conditionally render the navbar */}
      {!shouldHideNavbar && (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <Link className="navbar-brand col-md-1 text-center" to="/">
            <div className="col-md-3 text-center">
            <img style={{width:"100%"}} src="logo.png" alt="logo"/>  
            </div>
             
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                {/* <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/all-mocks">List Mock</Link>
                </li>
                
                {/* Admin Links */}
                {isAdmin && (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/upload">Upload Mock</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/assign">Assign Mock</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/all-attempt">All Student Attempt</Link>
                    </li>
                  </>
                )}
              </ul>
              {accessToken && (
                <button className="btn btn-dark ms-auto" onClick={handleLogout} type="button">Logout</button>
              )}
              {!accessToken && (
              
                <Link className="ms-auto" aria-current="page" to="/login"><button className="btn btn-dark ms-auto">Login</button></Link>
             
              )}
            </div>
          </div>
        </nav>
      )}
      <div className="content">{children}</div>
    </div>
  );
};

export default Layout;
