
import './Mock.css'
import '../../Assets/CSS/style.css'
import Desmos from 'desmos';
import { useDispatch, useSelector } from 'react-redux';
import Choice from '../../components/Choice';
import { setCurrentQuestion, fetchMock, setChoice, setMarkedQuestion, setModule, resetMock } from '../../redux/slice/mockSlice';
import { useState, useEffect, useRef } from 'react';
import QuestionsMenu from '../../components/QuestionMenu/QuestionsMenu';
import Timer from '../../components/Timer/Timer';
import { useNavigate, useParams } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import MathRenderer from '../../components/Math/MathRender';
import MathRule from '../../components/Math/MathRule';
import MathReview from '../../components/Math/MathReview';
import MathRender from '../../components/Math/MathRender';
import Loading from '../../components/Loading/Loading';
import Preview from '../../components/PopupNextSection/Preview';
import { updateAttempt } from '../../apis/updateAttempt';
import { calculateTotalScore } from '../../utils/calculateTotalScore';
import { setTime } from '../../redux/slice/timerSlice';

function Mock() {

    const dispatch = useDispatch();
    const [isPreview, setIsPreview] = useState(false);
    const mock = useSelector((state) => state.mock);
    const isLoading = useSelector(state => state.mock.isLoading);
    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const time = useSelector(state => state.timer.time);
    const { attemptId } = useParams(); // Lấy attemptId từ URL
    const [isOpenMenu, setOpenMenu] = useState(false)
    const currentQuestion = useSelector((state) => state.mock.currentQuestion);
    const isMarked = useSelector(state => state.mock.markedQuestions[currentQuestion.id]);
    const inputValue = useSelector(state => state.mock.choices[currentQuestion.id]);
    const handleMarkButton = () => {
        dispatch(setMarkedQuestion(currentQuestion.id));
    }
    const handleOpenMenu = () => {
        setOpenMenu(!isOpenMenu);
    }
    const handleBackButton = () => {
        if (currentQuestion.id === 1) {
            setIsPreview(false);
        } else if (isPreview) {
            setIsPreview(false);
        } else if (currentQuestion.id === mock.startAtQuestions + 1) {
            return;
        } else {
            dispatch(setCurrentQuestion(currentQuestion.id - 1));
        }
    }
    const handleTimeOut = () => {
        dispatch(setModule(mock.module + 1));
        switch (mock.module) {
            case 1:
                dispatch(setTime(32 * 60));
                break;
            case 2:
                dispatch(setTime(32 * 60));
                break;
            case 3:
                dispatch(setTime(35 * 60));
                break;
            case 4:
                dispatch(setTime(35 * 60));
                break;
            default:
                dispatch(setTime(32 * 60));
                break;
        }
        // dispatch(setCurrentQuestion(currentQuestion.id + 1));
    }
    const handleNextButton = () => {
        if (isPreview) {
            dispatch(setModule(mock.module + 1));
            switch (mock.module) {
                case 1:
                    dispatch(setTime(32 * 60));
                    break;
                case 2:
                    dispatch(setTime(32 * 60));
                    break;
                case 3:
                    dispatch(setTime(35 * 60));
                    break;
                case 4:
                    dispatch(setTime(35 * 60));
                    break;
                default:
                    dispatch(setTime(32 * 60));
                    break;
            }
            // dispatch(setCurrentQuestion(currentQuestion.id + 1));
            setIsPreview(false);
        } else if (currentQuestion.id === mock.endAtQuestions) {
            setIsPreview(true)
        } else {
            dispatch(setCurrentQuestion(currentQuestion.id + 1));
        }
    }



    const handleChangeInput = (event) => {
        dispatch(setChoice({ id: currentQuestion.id, choice: event.target.value }));
    }
    // Fetch data bài test từ server
    useEffect(() => {
        dispatch(fetchMock(attemptId))
        console.log(mock.excludedChoices)

        //eslint-disable-next-line
    }, [attemptId, dispatch]);
    // đá người dùng ra ngoài nếu attempt đã hoàn thành
    useEffect(() => {

        if (mock.attemptId === attemptId && mock.status === "FINISH") {
            navigate(`/score?attemptId=${attemptId}`);
        }


        //eslint-disable-next-line
    })

    // Update attempt data to server every 10 seconds
    useEffect(() => {
        if (time === 0) {
            if (mock.module !== 4) {
                handleTimeOut();
            } else {
                handleSubmit();
            }

        }
        if (time % 10 !== 0 || time === 32 * 60) return;
        updateAttempt(attemptId, mock, time); // Call the reusable function
        //eslint-disable-next-line
    }, [time]);
    // Submit bài test
    const handleSubmit = () => {
        updateAttempt(attemptId, mock, time, "FINISH", calculateTotalScore(mock.data, mock.choices));
        dispatch(resetMock());
        if (mock.type === "PRACTICE_TEST") {
            navigate(`/score?attemptId=${attemptId}`);
        } else {
            navigate('/test-completion');
        }

    }
    const [isDesmosOpen, setIsDesmosOpen] = useState(false); // State to control Desmos popup
    const desmosRef = useRef(null); // Reference to hold Desmos container
    const calculatorRef = useRef(null); // Reference for Desmos instance

    // ... existing code

    // Function to toggle Desmos visibility
    const toggleDesmos = () => {
        setIsDesmosOpen(!isDesmosOpen);
    };
    useEffect(() => {
        if (isDesmosOpen && desmosRef.current) {
            calculatorRef.current = Desmos.GraphingCalculator(desmosRef.current);
        }

        // Cleanup function to destroy Desmos when closed
        return () => {
            if (calculatorRef.current) {
                calculatorRef.current.destroy();
                calculatorRef.current = null;
            }
        };
    }, [isDesmosOpen]);
    
   

    if (isLoading) {
        return <Loading />
    }

    return (
        <div className="mock-page">
            {/* Your existing components */}

            {/* Desmos popup */}
            {isDesmosOpen && (
                <div className="desmos-popup-small" >
                    <div ref={desmosRef} style={{ width: '100%', height: '100%' }}></div>
                </div>
            )}

            <div className="text-center">
                <div className="mx-auto cold-md-6 py-2 main-card-test" style={{ position: 'relative' }}>
                    {/* Top Section */}
                    {!isPreview && <section className='mx- col-md-12  d-flex justify-content-between main-header-test'>
                        <div className="text-left" style={{ width: '200px' }}>
                            <p className="m-0 medium font-20">Section {mock.module===1|| mock.module===2?1:2}, Module {mock.module===1|| mock.module===3?1:2 }: {mock.module<3?'Reading and Writing':' Math'}</p>
                            <button className="btn px-0">
                                <strong>
                                    Directions &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                    </svg>
                                </strong>

                            </button>
                        </div>
                        {/* Timer */}
                        <div className="align-items-center ">

                            <Timer />
                        </div>
                        <div className="d-flex view-more">
                            <button className='medium d-inline btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-highlighter" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.096.644a2 2 0 0 1 2.791.036l1.433 1.433a2 2 0 0 1 .035 2.791l-.413.435-8.07 8.995a.5.5 0 0 1-.372.166h-3a.5.5 0 0 1-.234-.058l-.412.412A.5.5 0 0 1 2.5 15h-2a.5.5 0 0 1-.354-.854l1.412-1.412A.5.5 0 0 1 1.5 12.5v-3a.5.5 0 0 1 .166-.372l8.995-8.07zm-.115 1.47L2.727 9.52l3.753 3.753 7.406-8.254zm3.585 2.17.064-.068a1 1 0 0 0-.017-1.396L13.18 1.387a1 1 0 0 0-1.396-.018l-.068.065zM5.293 13.5 2.5 10.707v1.586L3.707 13.5z" />
                                </svg>
                                <br />
                                Highlight & Note
                            </button>
                            {currentQuestion.skill !== "RW" && <button className='medium d-inline btn' onClick={toggleDesmos}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calculator-fill" viewBox="0 0 16 16">
                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm2 .5v2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5m0 4v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M4.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 12.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M7.5 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM7 9.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM10 6.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m.5 2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5z" />
                                </svg>
                                Calculator
                            </button>}
                            <button className='medium d-inline btn' >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                </svg>
                                <br />
                                More
                            </button>
                        </div>
                    </section>}
                    <hr style={{ border: '1px solid #000', margin: '0 0 15px 0' }} />
                    {/* Middle Section  */}
                    {isPreview ?
                        <Preview setIsPreview={setIsPreview} /> :
                        <section className="mid-section col-md-12 row text-center overflow-auto">

                            {/* Left Container */}

                            {currentQuestion.skill === 'MI' ?
                                <MathRule />
                                :
                                <div className={`
        overflow-auto 
        col-sm-12 
        ${currentQuestion.skill === "MA" ? "col-md-12" : "col-md-6 row-with-divider"} 
        p-4  
        text-start
        left-divider`}>

                                    <div>
                                        <MathRender text={currentQuestion.passage} />
                                    </div>


                                </div>}
                            {/* Right Container */}
                           
                            <div className={`overflow-auto  col-sm-12  
       ${currentQuestion.skill === "MA" ? "col-md-7 m-auto" : "col-md-6 row-with-divider"} 
       row-with-divider p-4`}>
                                <div className="d-flex title-container">
                                    <p className="question-number">{mock.module === 1 ? currentQuestion.id : currentQuestion.id - mock.startAtQuestions}</p>
                                    <button className="btn d-flex align-items-center mark-btn" onClick={() => handleMarkButton()}>
                                        <span>
                                            {!isMarked ? <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-bookmark " viewBox="0 0 16 16">
                                                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z" />
                                            </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-bookmark-fill text-danger" viewBox="0 0 16 16">
                                                    <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2" />
                                                </svg>}

                                            &nbsp;Mark for Review
                                        </span>
                                    </button>
                                </div>

                                <p className="my-4 text-start" id="question"><MathRenderer text={currentQuestion.question} /></p>
                                {/* Choices Container */}
                                <div className="choices-container">

                                    {currentQuestion.skill === "MI" ?
                                        <div>
                                            <input type="text" className="form-control choice-input" placeholder="Your answer" value={inputValue} onChange={handleChangeInput} />
                                            <h5 className="text-start mt-2">Answer Preview: &nbsp; <MathReview input={inputValue} /> </h5>

                                        </div>
                                        :
                                        currentQuestion.choices.map((choice, index) =>
                                            <Choice key={`${choice.key}-${index}`} customKey={choice.key} text={choice.text} />
                                        )}

                                </div>
                            </div>
                        </section>}

                </div>
                {/* Bottom Section */}
                <section className="botton-section container-fluid ">
                    <div className="col-md-2 d-flex align-items-center ">
                        <h5>{user.name}</h5>
                    </div>
                    <div className="col-md-8 question-menu-container">
                        {isOpenMenu && <QuestionsMenu handleOpenMenu={handleOpenMenu} />}
                        {!isPreview &&
                            <button className="btn btn-dark" onClick={() => handleOpenMenu()}>
                                Question {mock.module === 1 ? currentQuestion.id : currentQuestion.id - mock.startAtQuestions} of {mock.endAtQuestions - mock.startAtQuestions} &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                </svg>
                            </button>}

                    </div>
                    <div className="col-md-2">
                        <button onClick={() => handleBackButton()} className="btn btn-dark me-3 rounded-pill ">Back</button>

                        {mock.module === 4 && isPreview ? <button onClick={() => handleSubmit()} className="btn btn-dark rounded-pill">Submit</button> :
                            <button onClick={() => handleNextButton()} className="btn btn-dark rounded-pill">Next</button>}
                    </div>
                </section>
            </div>

        </div>
    )
}

export default Mock