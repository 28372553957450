import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './QuestionsMenu.css';
import { setCurrentQuestion } from '../../redux/slice/mockSlice';


export default function QuestionsMenu({ handleOpenMenu }) {
     
    const endAtQuestions=useSelector(state=>state.mock.endAtQuestions);
    const startAtQuestions=useSelector(state=>state.mock.startAtQuestions);
    const mock = useSelector(state => state.mock);
    const module = useSelector(state=>state.mock.module);
    
    const dispatch = useDispatch();
    const handleChooseQuestion = (id) => {
        dispatch(setCurrentQuestion(id));
        handleOpenMenu();
    }

    const menu = useSelector(state => state.mock.menu);
   
    const markedQuestions = useSelector(state => state.mock.markedQuestions);
 
    return (
        <div className="questions-menu pt-4">
            <button className="btn exit-btn" onClick={() => handleOpenMenu()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                </svg>
            </button>
            <div className='menu-header text-center'>
                <h2>Section {mock.module===1|| mock.module===2?1:2}, Module {mock.module===1|| mock.module===3?1:2 }:{mock.module<3?'Reading and Writing':' Math'} </h2>
                <hr />
            </div>
            <div className='menu-body text-center'>
                <span className='px-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className=" bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                    </svg>
                    Current
                </span>
                <span className='px-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                    </svg>
                    Unaswered
                </span>
                <span className='px-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-fill text-danger" viewBox="0 0 16 16">
                        <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2" />
                    </svg>
                    For review
                </span>
            </div>
            <div className="menu-body">
                {mock.data.slice(startAtQuestions,endAtQuestions).map((question) => {
                    // Chọn lớp CSS dựa trên trạng thái của câu hỏi
                    const buttonClass = menu[question.id].isAnswered === true ? 'btn-outline-main-color-selected' :
                        'btn-outline-dark';

                    return (
                        <button
                            key={question.id}
                            className={`btn mx-1 my-2 p-1 btn-question ${buttonClass}`}
                            onClick={() => handleChooseQuestion(question.id)}
                        >
                            {menu[question.id].isCurrent === true && <span className='current-container'>

                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-geo-alt-fill current-css`} viewBox="0 0 16 16">
                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                </svg>
                            </span>}
                            {markedQuestions[question.id] && <span className='mark-container'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-bookmark-fill text-danger mark-css" viewBox="0 0 16 16">
                                    <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2" />
                                </svg>
                            </span>}


                            {module===1?question.id:question.id - startAtQuestions}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}