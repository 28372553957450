
import React, { useEffect, useState } from 'react';
const Home = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/home.html`)
      .then((response) => response.text())
      .then((data) => setHtmlContent(data));
  }, []);

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default Home
