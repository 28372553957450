import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchMock } from '../../redux/slice/mockSlice';
import { calculateTotalScore } from '../../utils/calculateTotalScore'; // Import the score calculation function
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure you've imported Bootstrap CSS
import './ScorePage.css';
const ScorePage = () => {
    const mockData = useSelector((state) => state.mock.data);
    const type = useSelector((state) => state.mock.type);
    const studentChoices = useSelector((state) => state.mock.choices);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [scoreSections, setScoreSections] = useState({ section1: 0, section2: 0, section3: 0, section4: 0 });
    const [detailedScores, setDetailedScores] = useState([]); // New state to hold detailed score information

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const attemptId = searchParams.get('attemptId');

    useEffect(() => {
        if (attemptId) {
            dispatch(fetchMock(attemptId));
        }
    }, [dispatch, attemptId]);

    useEffect(() => {
        if (mockData && studentChoices) {
            // Calculate scores for each section
            const section1 = mockData.slice(0, 27);
            const section2 = mockData.slice(27, 54);
            const section3 = mockData.slice(54, 76);
            const section4 = mockData.slice(76, 98);

            const section1Score = calculateTotalScore(section1, studentChoices);
            const section2Score = calculateTotalScore(section2, studentChoices);
            const section3Score = calculateTotalScore(section3, studentChoices);
            const section4Score = calculateTotalScore(section4, studentChoices);

            setScoreSections({
                section1: section1Score,
                section2: section2Score,
                section3: section3Score,
                section4: section4Score,
                total: section1Score + section2Score + section3Score + section4Score,
            });

            // Populate the detailedScores state with question-level data
            const details = [
                ...section1.map((question, index) => ({
                    questionNumber: index + 1,
                    section: "Module 1 :R & W",
                    correctAnswer: question.answer,
                    studentAnswer: studentChoices[question.id] || "Skip",
                    isCorrect: question.answer.split(';').includes(studentChoices[question.id]),
                })),
                ...section2.map((question, index) => ({
                    questionNumber: index + 1,
                    section: "Module 2 :R & W",
                    correctAnswer: question.answer,
                    studentAnswer: studentChoices[question.id] || "Skip",
                    isCorrect: question.answer.split(';').includes(studentChoices[question.id]),
                })),
                ...section3.map((question, index) => ({
                    questionNumber: index + 1,
                    section: "Module 1: Math",
                    correctAnswer: question.answer,
                    studentAnswer: studentChoices[question.id] || "Skip",
                    isCorrect: question.answer.split(';').includes(studentChoices[question.id]),
                })),
                ...section4.map((question, index) => ({
                    questionNumber: index + 1,
                    section: "Module 2: Math",
                    correctAnswer: question.answer,
                    studentAnswer: studentChoices[question.id] || "Skip",
                    isCorrect: question.answer.split(';').includes(studentChoices[question.id]),
                })),
            ];

            setDetailedScores(details);
        }
    }, [mockData, studentChoices]);

    if (!mockData) return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );



    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center">
                    <div className="card shadow-sm mb-4">
                        <div className="card-body">
                            <h1 className="card-title text-center mb-4">Score Page</h1>
                            <div className="mb-4">
                                <h4 className="text-success">Section Scores</h4>
                                <ul className="list-group">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        <strong>Section 1:</strong>
                                        <span>{scoreSections.section1} / 27</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        <strong>Section 2:</strong>
                                        <span>{scoreSections.section2} / 27</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        <strong>Section 3:</strong>
                                        <span>{scoreSections.section3} / 22</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        <strong>Section 4:</strong>
                                        <span>{scoreSections.section4} / 22</span>
                                    </li>
                                </ul>
                            </div>
                            <hr />
                            <div className="text-center">
                                <h4 className="text-primary">Total Score</h4>
                                <p className="display-4 text-info">
                                    {scoreSections.total} / {mockData.length}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Detailed Score Table */}
                    <div className="">
                        <div className="">
                            <h4 className="text-center mb-4">Detail Score </h4>
                            <table className="table  table-no-vertical  p-4 rounded table-striped">
                                <thead class="table-secondary ms-4 rounded">
                                    <tr>
                                        <th style={{ "width": "12%" }} className='text-start ms-4 fw-medium'> &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            Question </th>
                                        <th style={{ "width": "19%" }} className='fw-medium'></th>
                                        <th className='fw-medium'>Correct Answer</th>
                                        <th className='fw-medium'>Your Answer</th>

                                        <th className='fw-medium'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detailedScores.map((detail, index) => (
                                        <tr key={index} >
                                            <td className=" text-start ms-4">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Question {detail.questionNumber}

                                            </td>
                                            <td className=" text-start">
                                                {detail.section}

                                            </td>



                                            <td className="fw-bold">{detail.correctAnswer.replace(";", " OR ")} </td>
                                            <td className={detail.isCorrect ? "correct-answer" : (detail.studentAnswer === "Skip" ? "skip-answer" : "wrong-answer")} >
                                                {detail.studentAnswer !== "Skip" && detail.studentAnswer}  &nbsp;
                                                {detail.studentAnswer === "Skip" ? "--- Skipped" : (detail.isCorrect ? "Correct" : "Incorrect")}
                                            </td>

                                            <td className="text-blue">Review</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScorePage;
