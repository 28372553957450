import React, { useEffect, useState } from 'react';
import { fetchAtteptByUserIdAPI } from '../../apis';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns'; // Import the format function from date-fns
import { useDispatch, useSelector } from 'react-redux';
import { resetMock } from '../../redux/slice/mockSlice';
import './MockList.css'; // Import your CSS file

const MockList = () => {
  const [attemptList, setAttemptList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.id);
  const handleStartMock = (attemptId) => {
    navigate(`/mock/${attemptId}`);
    dispatch(resetMock());
  };

  const handleScoreAttempt = (attemptId) => {
    dispatch(resetMock());
    navigate(`/score?attemptId=${attemptId}`);
  };

  useEffect(() => {
    setLoading(true);
    const fetchMockData = async () => {
      try {
        const response = await fetchAtteptByUserIdAPI(userId);
        const data = response.data.result;
        setAttemptList(data);
      } catch (error) {
        console.error("Error fetching attempts:", error);
      } finally {
        setLoading(false);
      }
    };
    dispatch(resetMock());
    fetchMockData();
    // eslint-disable-next-line 
  }, [userId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Attempt List </h2>
      <table className="table table-striped table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
          
            <th scope="col">#</th>
            <th scope="col">Mock Name</th>
            <th scope="col">Type</th>
            <th scope="col">Deadline</th>
            
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {attemptList.length > 0 ? (
            attemptList.map((attempt, index) => (
              <tr key={attempt.attemptId}>
                <th scope="row">{index + 1}</th>
                <td>
                  {/* Format deadlineTime using date-fns */}
                  {attempt.mockName}
                </td>
                <td>
                  {attempt.type === 'PRACTICE_TEST' ? 'Practice Test' : 'Entrance Test'}
                </td>
                <td>
                  {/* Format deadlineTime using date-fns */}
                  {format(new Date(attempt.deadlineTime), 'dd-MM-yyyy, hh:mm a')}
                </td>
                
                <td>
                  {/* Apply status CSS class based on status value */}
                  <span className={
                    attempt.status === 'FINISH' ? 'status-finish' :
                      attempt.status === 'IN_PROCESS' ? 'status-in-progress' :
                        'status-not-started'
                  }>
                    {attempt.status}
                  </span>
                </td>
                
                <td>
                  {attempt.status === 'FINISH' ? (
                    attempt.type==="PRACTICE_TEST" &&
                    <button
                      className="btn btn-secondary mx-1"
                      onClick={() => handleScoreAttempt(attempt.attemptId)}
                    >
                      Result
                    </button>
                  ) : (
                    <button
                      className="btn btn-dark mx-1"
                      onClick={() => handleStartMock(attempt.attemptId)}
                    >
                      Start
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">No attempts found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MockList;
