import React from 'react'
import './Preview'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentQuestion } from '../../redux/slice/mockSlice';
const Preview = (props)=> {
    const dispatch = useDispatch();
    const mock = useSelector(state => state.mock);
    const menu = useSelector(state => state.mock.menu);
    const markedQuestions = useSelector(state => state.mock.markedQuestions);
    const handleChooseQuestion = (id) => {
        dispatch(setCurrentQuestion(id));
        props.setIsPreview(false);
    }
    const endAtQuestions=useSelector(state=>state.mock.endAtQuestions);
    const startAtQuestions=useSelector(state=>state.mock.startAtQuestions);
  return (
      <div className='container text-ceter'>
              <h3>Check Your Work</h3>
              <h5>
                On test day, you wont'be able to move on to the next module until time expires<br/>
                For these questions, you can click <strong>Next</strong> when you're ready to move on
              </h5>
              
      
           <div className="questions-menu pt-4" style={{position:"static ",margin:"18vh"}}>
            
            <div className='menu-header text-center'>
            <h2>Section {mock.module===1|| mock.module===2?1:2}, Module {mock.module===1|| mock.module===3?1:2 }: {mock.module<3?'Reading and Writing':' Math'}</h2>
                <hr />
            </div>
            <div className='menu-body text-center'>
                
                <span className='px-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                    </svg>
                    Unaswered
                </span>
                <span className='px-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-fill text-danger" viewBox="0 0 16 16">
                        <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2" />
                    </svg>
                    For review
                </span>
            </div>
            <div className="menu-body text-center">
                {mock.data.slice(startAtQuestions,endAtQuestions).map((question) => {
                    // Chọn lớp CSS dựa trên trạng thái của câu hỏi
                    const buttonClass = menu[question.id].isAnswered === true ? 'btn-outline-main-color-selected' :
                        'btn-outline-dark';

                    return (
                        <button
                            key={question.id}
                            className={`btn mx-1 my-2 p-1 btn-question ${buttonClass}`}
                            onClick={() => handleChooseQuestion(question.id)}
                        >
                           
                            {markedQuestions[question.id] && <span className='mark-container'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-bookmark-fill text-danger mark-css" viewBox="0 0 16 16">
                                    <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2" />
                                </svg>
                            </span>}


                            {mock.module===1?question.id:question.id -startAtQuestions}
                        </button>
                    );
                })}
            </div>
        </div>
        </div>
    
  )
}
export default Preview

