import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { fetchAtteptByIdAPI } from '../../apis';



export const fetchMock = createAsyncThunk('mock/fetchMock', async (attemptId) => {

    const response = await fetchAtteptByIdAPI(attemptId);
    const result = response.data.result;
    const resultData = {
        attemptId: result.attemptId,
        mock: JSON.parse(result.mock),
        module: result.module,
        file: JSON.parse(result.file),
        time: result.timer,
        status: result.status,
        type: result.type

    }
    return resultData;
})
const initialState= {
    isLoading: false,
    data: null,
    isError: false,
    status:"NOT_START",
    attemptId: null,
    module: 1,
    type: "",
    startAtQuestions: 0,
    endAtQuestions: 27,
    section: 1,
    currentQuestion: {
        id: 1,
        skill: "RW",
        subId: 1,
        answer: "",
        passage: "",
        question: "",
        choices: [
            {
                "key": "",
                "text": "",
            },
            {
                "key": "",
                "text": "",
            },
            {
                "key": "",
                "text": "",
            },
            {
                "key": "",
                "text": "",
            }
        ],
    },
    choices: {},
    menu: {},
    excludedChoices: {},
    markedQuestions: {},
    currentMockId: 0,
    isDataFetched: false
}
const mockSlice = createSlice({
    name: 'mock',
    initialState,
    reducers: {
        setCurrentQuestion: (state, action) => {
            state.currentQuestion = state.data[action.payload - 1];
            for (let i = 1; i <= state.data.length; i++) {
                state.menu[i].isCurrent = false;
            }
            state.menu[action.payload].isCurrent = true;

        },
        setModule: (state, action) => {
            state.module = action.payload;
            if (action.payload === 1 || action.payload === 2) {
                state.startAtQuestions = state.startAtQuestions + 27
                state.currentQuestion = state.data[state.startAtQuestions];
                state.endAtQuestions = state.endAtQuestions + 27;
            } else if (action.payload === 3) {
                state.startAtQuestions = state.startAtQuestions + 27
                state.currentQuestion = state.data[state.startAtQuestions];
                state.endAtQuestions = state.endAtQuestions + 22;
            } else if (action.payload === 4) {
                state.startAtQuestions = state.startAtQuestions + 22
                state.currentQuestion = state.data[state.startAtQuestions];
                state.endAtQuestions = state.endAtQuestions + 22;
            }
            // state.currentQuestion = state.data[state.startAtQuestions];
        },
        setChoice: (state, action) => {
            const { id, choice } = action.payload;
            state.choices[id] = choice;
            state.menu[id].isAnswered = true;
            if (!choice) {
                state.menu[id].isAnswered = false;
            }
        },
        addExCludeChoice: (state, action) => {
            const { id, choice } = action.payload;
            state.excludedChoices[id][choice] = !state.excludedChoices[id][choice];
        },
        setMarkedQuestion: (state, action) => {

            state.markedQuestions[action.payload] = !state.markedQuestions[action.payload];
        },
        removeChoice: (state, action) => {
            state.choices = state.choices.filter(choice => choice !== action.payload);
        },
        resetMock: (state) => {
            return initialState;
        }
        

    },
    extraReducers: (builder) => {
        builder.addCase(fetchMock.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchMock.fulfilled, (state, action) => {
            const { mock, file, module ,status,attemptId,type} = action.payload; // mock là dữ liệu mock, file là dữ liệu sao lưu đc lấy lên từ API
            console.log(mock)
            console.log(file)
            state.data = mock;
            state.module = module;
            state.status =status;
            state.attemptId = attemptId;
            state.type=type;
            
            if (file != null ) {
                state.startAtQuestions = file.startAtQuestions;
                state.endAtQuestions = file.endAtQuestions;
                state.currentQuestion = file.currentQuestion;
                if(file.menu){
                    state.menu = file.menu;
                }
                if(file.choices){
                    state.choices = file.choices;
                }
              
                if(file.excludedChoices){
                    state.excludedChoices = file.excludedChoices;
                }
           
            } else {
                state.currentQuestion = mock[0];

                for (let i = 1; i <= mock.length; i++) {
                    state.menu[i] = {
                        isCurrent: false,
                        isAnswered: false,
                        isMarked: false,
                    };
                    state.excludedChoices[i] = {
                        A: false,
                        B: false,
                        C: false,
                        D: false,
                    }
                }
            }
            state.isDataFetched = true;
            state.isLoading = false;
            // if(state)

        })
        builder.addCase(fetchMock.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true;
        })
    }
})
// Export các action
export const { setCurrentQuestion, setChoice, addExCludeChoice, resetMock, setMarkedQuestion, setModule } = mockSlice.actions;
export default mockSlice.reducer;