import React from 'react'
import '../pages/Mock/Mock.css';
import { useDispatch, useSelector } from 'react-redux';
import { setChoice,addExCludeChoice } from '../redux/slice/mockSlice';
import MathRender from './Math/MathRender';

export default function Choice(props) {
    const dispatch = useDispatch();
    const currentQuestion = useSelector((state) => state.mock.currentQuestion);
    const isExcludedChoice = useSelector((state) => state.mock.excludedChoices[currentQuestion.id])?.[props.customKey];
    const handleChoiceSelect = (choice) => {
        dispatch(setChoice({ id: currentQuestion.id, choice: choice }));
    }
    const handleExcludeChoice = ()=>{
        dispatch(addExCludeChoice({id: currentQuestion.id, choice: props.customKey}));
    }

    const selectedChoice = useSelector(state => state.mock.choices[currentQuestion.id]);
    return (
        <div className="choice-container d-flex">
            {/* chia ra 2 case có exclude và không có exclude */}
            <button
                value={props.customKey}
                className={`choice-btn text-start px-3 py-2 w-100 my-2 btn 
                     ${isExcludedChoice ? 'strikethrough-box' : ''}
                     ${selectedChoice === props.customKey ? 'btn-choice-outline-main-color-selected' :
                        'btn-outline-main-color'}`}
                onClick={() => handleChoiceSelect(props.customKey)}
           ><div className='d-flex align-item'>
                <div className={`${selectedChoice === props.customKey ?
                    'btn-key-selected' : 'btn-key'
                }`}>{props.customKey}</div>
                <MathRender text={props.text} />
            </div> 
       
       
            </button>
            <button className="exclude-btn btn" onClick={()=>handleExcludeChoice()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                </svg>
            </button>
        </div>
    )
}
