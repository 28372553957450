import { API_ROOT } from '../utils/constants';
import api from '../utils/authorizedAxious';
import axios from 'axios';



//* Authentication *//

export const fetchTokenAPI = async (username, password) => {
    const response = await api.post(`${API_ROOT}auth/login`, { username, password });
    return response;
}
export const fetchLogoutAPi = async (token) =>{
    const response = await axios.post(`${API_ROOT}auth/logout`,{token})
    return response;
}



//* User *//


export const fetchUsersAPI = async () => {
    const response = await api.get('/users');
    return response.data.result;
};
export const fetchMyProfileAPI = async () => {
    const response = await api.get('/users/my-info');
    return response.data.result;
}

// * Attempt * //
export const createAttemptAPI = async (attemptData) => {
    const response = await api.post('/attempts', attemptData);
    return response.data;
}
export const fetchAtteptByUserIdAPI = async (userId) => {
    const response = await api.get(`/attempts/userId/${userId}`);
    return response;
}
export const fetchAtteptByIdAPI = async (attemptId) => {
    const response = await api.get(`/attempts/${attemptId}`);
    return response;
}
export const fetchAllAttemptsAPI = async () => {
    const response = await api.get('/attempts');
    return response;
}


//Cập nhật dữ liệu Attempt
export const updateAttemptAPI = async (attemptId, attemptData) => {
    const response = await api.put(`/attempts/${attemptId}`, attemptData,{
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
}

// * Mock * //
export const fetchMocksAPI = async () => {
    const response = await api.get('/mocks');
    return response.data.result;
}
