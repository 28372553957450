import React, { useState } from "react";
import axios from "axios";
import { API_ROOT } from "../../utils/constants";

const UploadMock = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [data, setData] = useState(null);

  const handleFileChange = (e) => {
    setData(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Tạo formData để gửi multipart/form-data
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("data", data);

    try {
      // Gửi request đến API của bạn
      const response = await axios.post(API_ROOT+"mocks", formData, {
        headers: {
          "Content-Type": "multipart/form-data",

        },
      });

      // In response nếu thành công
      console.log(response.data);
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  return (
    <div>
      <h1>Create Mock</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Description:</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Upload JSON File:</label>
          <input type="file" onChange={handleFileChange} required />
        </div>
        <button type="submit">Create Mock</button>
      </form>
    </div>
  );
};

export default UploadMock;
