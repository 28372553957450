



// Lưu token vào localStorage
export const login = (token) => {
    localStorage.setItem('token', token);

  };
  

  export const logout = () => { 
   localStorage.clear();// Xóa token khỏi localStorage
  };
  
  