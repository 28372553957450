// AssignAttempt.js
import React, { useState, useEffect } from 'react';
import { fetchUsersAPI, fetchMocksAPI, createAttemptAPI } from '../../apis';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AssignAttempt() {
  const [username, setUsername] = useState('');
  const [mockName, setMockName] = useState('');
  const [attemptType, setAttemptType] = useState('');
  const [deadlineTime, setDeadlineTime] = useState('');
  const [users, setUsers] = useState([]);
  const [mocks, setMocks] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch data for Users and Mocks from API when component is rendered
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const usersData = await fetchUsersAPI();
        const mocksData = await fetchMocksAPI();
        setUsers(usersData);
        setMocks(mocksData);
      } catch (error) {
        toast.error('Error fetching data from the server.');
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Convert the deadlineTime to the correct format
    const formattedDeadlineTime = new Date(deadlineTime).toISOString();

    const attemptData = {
      username,
      mockName,
      type: attemptType,
      deadlineTime: formattedDeadlineTime, // Use the correctly formatted date
    };
    console.log(attemptData);

    try {
      const response = await createAttemptAPI(attemptData);
      toast.success('Mock assigned successfully!');
      console.log(response);
      // Clear the form fields after a successful submission
      setUsername('');
      setMockName('');
      setDeadlineTime('');
      setAttemptType('');
    } catch (error) {
      toast.error('Error assigning mock.');
      console.error('Error assigning mock:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Assign Mock to Student</h2>
              {loading ? (
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <label htmlFor="username" className="form-label">Username:</label>
                    <select
                      className="form-control"
                      id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    >
                      <option value="" disabled>Select a user</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.username}>
                          Username: {user.username} ---- Fullname: {user.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="attemptType" className="form-label">Attempt Type:</label>
                    <select
                      className="form-control"
                      id="attemptType"
                      value={attemptType}
                      onChange={(e) => setAttemptType(e.target.value)}
                      required
                    >
                      <option value="" disabled>Select attempt type</option>
                      <option key="PRACTICE_TEST" value="PRACTICE_TEST">Practice Test</option>
                      <option key="ENTRANCE_TEST" value="ENTRANCE_TEST">Entrance Test</option>
                    </select>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="mockName" className="form-label">Mock Name:</label>
                    <select
                      className="form-control"
                      id="mockName"
                      value={mockName}
                      onChange={(e) => setMockName(e.target.value)}
                      required
                    >
                      <option value="" disabled>Select a mock</option>
                      {mocks.map((mock) => (
                        <option key={mock.id} value={mock.name}>
                          {mock.name}: {mock.description}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="deadlineTime" className="form-label">Deadline Time:</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="deadlineTime"
                      value={deadlineTime}
                      onChange={(e) => setDeadlineTime(e.target.value)}
                      required
                    />
                  </div>

                  <button type="submit" className="btn btn-primary w-100">Assign Mock</button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignAttempt;
