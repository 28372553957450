import { createSlice } from '@reduxjs/toolkit';
import { fetchMock } from './mockSlice';

// State ban đầu cho time
const initialState = {
    time: 32*60, // Giá trị thời gian ban đầu
};

// Tạo slice cho time
const timerSlice = createSlice({
    name: 'time',
    initialState,
    reducers: {
        
        resetTime: (state) => {
            state.time = 0;
        },
        setTime: (state, action) => {
            state.time = action.payload;
        },
    },
    extraReducers: (builder) =>{
        builder.addCase(fetchMock.fulfilled, (state, action) => {
            if(action.payload){
                state.time = action.payload.time;
            }
           
        });
    }
});

// Export các action
export const { increment, reset, setTime } = timerSlice.actions;

// Export reducer
export default timerSlice.reducer;