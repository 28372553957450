export const calculateTotalScore = (mockData, studentChoices) => {
    if (!mockData || !studentChoices) return 0;

    let score = 0;
    mockData.forEach((question) => {
        if ( question.answer.split(';').includes(studentChoices[question.id])) {
            score++;
        }
    });

    return score;
};
