import React from 'react'
import './MathRule.css'
export default function MathRule() {
    return (
        <div className="col-md-6 p-4 text-start scrollable-container left-divider ">
            <div className="my-3"></div>
            <p className="font-20 medium">Student-produced response directions</p>
            <ul>
                <li>Enter only <span ><strong>one correct answer</strong></span>, even if there are multiple correct answers.</li>
                <li>You can enter up to 5 characters for a positive answer and up to 6 characters for a negative answer (including negative sign)</li>
                <li>If your answer is a fraction but it doesn't fit in the provided space, enter the decimal equivalent instead.</li>
                <li>If your answer is a decimal but it doesn't fit in the provided space, round it to 4 digits.</li>
                <li>If your answer is a mixed number (such as &nbsp;
                    <span>
                        <span data-testid="react-katex">
                            <span className="katex">
                                <span className="katex-mathml">
                                    <math xmlns="http://www.w3.org/1998/Math/MathML">
                                        <semantics>
                                            <mrow>
                                                <mn>5</mn>
                                                <mfrac><mn>1</mn><mn>2</mn></mfrac>
                                            </mrow>
                                            <annotation encoding="application/x-tex">5\frac{1}{2}</annotation>
                                        </semantics>
                                    </math>
                                </span>
                                <span className="katex-html" aria-hidden="true">
                                    <span className="base">
                                        <span className="strut" style={{ height: "1.1901em", verticalAlign: "-0.345em" }}></span>
                                        <span className="mord">5</span>
                                        <span className="mord">
                                            <span className="mopen nulldelimiter"></span>
                                            <span className="mfrac">
                                                <span className="vlist-t vlist-t2">
                                                    <span className="vlist-r">
                                                        <span className="vlist" style={{ height: "0.8451em" }}>
                                                            <span style={{ top: "-2.655em" }}>
                                                                <span className="pstrut" style={{ height: "3em" }}></span>
                                                                <span className="sizing reset-size6 size3 mtight">
                                                                    <span className="mord mtight">
                                                                        <span className="mord mtight">2</span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                            <span style={{ top: "-3.23em" }}>
                                                                <span className="pstrut" style={{ height: "3em" }}></span>
                                                                <span className="frac-line" style={{ borderBottomWidth: "0.04em" }}></span>
                                                            </span>
                                                            <span style={{ top: "-3.394em" }}>
                                                                <span className="pstrut" style={{ height: "3em" }}></span>
                                                                <span className="sizing reset-size6 size3 mtight">
                                                                    <span className="mord mtight">
                                                                        <span className="mord mtight">1</span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span className="vlist-s">​</span>
                                                    </span>
                                                    <span className="vlist-r">
                                                        <span className="vlist" style={{ height: "0.345em" }}>
                                                            <span></span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <span className="mclose nulldelimiter"></span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>), enter it as an improper fraction (
                    <span>
                        <span data-testid="react-katex">
                            <span className="katex">
                                <span className="katex-mathml">
                                    <math xmlns="http://www.w3.org/1998/Math/MathML">
                                        <semantics>
                                            <mrow>
                                                <mfrac><mn>11</mn><mn>2</mn></mfrac>
                                            </mrow>
                                            <annotation encoding="application/x-tex">\frac{11}{2}</annotation>
                                        </semantics>
                                    </math>
                                </span>
                                <span className="katex-html" aria-hidden="true">
                                    <span className="base">
                                        <span className="strut" style={{ height: "1.1901em", verticalAlign: "-0.345em" }}></span>
                                        <span className="mord">
                                            <span className="mopen nulldelimiter"></span>
                                            <span className="mfrac">
                                                <span className="vlist-t vlist-t2">
                                                    <span className="vlist-r">
                                                        <span className="vlist" style={{ height: "0.8451em" }}>
                                                            <span style={{ top: "-2.655em" }}>
                                                                <span className="pstrut" style={{ height: "3em" }}></span>
                                                                <span className="sizing reset-size6 size3 mtight">
                                                                    <span className="mord mtight">
                                                                        <span className="mord mtight">2</span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                            <span style={{ top: "-3.23em" }}>
                                                                <span className="pstrut" style={{ height: "3em" }}></span>
                                                                <span className="frac-line" style={{ borderBottomWidth: "0.04em" }}></span>
                                                            </span>
                                                            <span style={{ top: "-3.394em" }}>
                                                                <span className="pstrut" style={{ height: "3em" }}></span>
                                                                <span className="sizing reset-size6 size3 mtight">
                                                                    <span className="mord mtight">
                                                                        <span className="mord mtight">11</span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span className="vlist-s">​</span>
                                                    </span>
                                                    <span className="vlist-r">
                                                        <span className="vlist" style={{ height: "0.345em" }}>
                                                            <span></span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <span className="mclose nulldelimiter"></span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>) or decimal (5.5).</li>
                <li>Don't enter symbols such as percent sign, comma or dollar sign.</li>
            </ul>
            <div className="my-3"></div>
            <p className="text-center font-18">Examples</p>
            <table className="table-bordered px-3 mx-auto">
                <thead>
                    <tr>
                        <th className="p-3">Answer</th>
                        <th className="p-3">Acceptable answers</th>
                        <th className="p-3">Unacceptable answers (no credit)</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <td>3.5</td>
                        <td>3.5<br />3.50<br />7/2</td>
                        <td>31/2<br />3 1/2</td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <span data-testid="react-katex">
                                    <span className="katex">
                                        <span className="katex-mathml">
                                            <math xmlns="http://www.w3.org/1998/Math/MathML">
                                                <semantics>
                                                    <mrow>
                                                        <mfrac><mn>2</mn><mn>3</mn></mfrac>
                                                    </mrow>
                                                    <annotation encoding="application/x-tex">\frac{2}{3}</annotation>
                                                </semantics>
                                            </math>
                                        </span>
                                        <span className="katex-html" aria-hidden="true">
                                            <span className="base">
                                                <span className="strut" style={{ height: "1.1901em", verticalAlign: "-0.345em" }}></span>
                                                <span className="mord">
                                                    <span className="mopen nulldelimiter"></span>
                                                    <span className="mfrac">
                                                        <span className="vlist-t vlist-t2">
                                                            <span className="vlist-r">
                                                                <span className="vlist" style={{ height: "0.8451em" }}>
                                                                    <span style={{ top: "-2.655em" }}>
                                                                        <span className="pstrut" style={{ height: "3em" }}></span>
                                                                        <span className="sizing reset-size6 size3 mtight">
                                                                            <span className="mord mtight">
                                                                                <span className="mord mtight">3</span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <span style={{ top: "-3.23em" }}>
                                                                        <span className="pstrut" style={{ height: "3em" }}></span>
                                                                        <span className="frac-line" style={{ borderBottomWidth: "0.04em" }}></span>
                                                                    </span>
                                                                    <span style={{ top: "-3.394em" }}>
                                                                        <span className="pstrut" style={{ height: "3em" }}></span>
                                                                        <span className="sizing reset-size6 size3 mtight">
                                                                            <span className="mord mtight">
                                                                                <span className="mord mtight">2</span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <span className="vlist-s">​</span>
                                                            </span>
                                                            <span className="vlist-r">
                                                                <span className="vlist" style={{ height: "0.345em" }}>
                                                                    <span></span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <span className="mclose nulldelimiter"></span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </td>
                        <td>2/3<br />.6666<br />.6667<br />0.666<br />0.667</td>
                        <td>0.66<br />.66<br />0 .67<br />.67</td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <span data-testid="react-katex">
                                    <span className="katex">
                                        <span className="katex-mathml">
                                            <math xmlns="http://www.w3.org/1998/Math/MathML">
                                                <semantics>
                                                    <mrow>
                                                        <mfrac>
                                                            <mrow><mo>−</mo><mn>1</mn></mrow><mn>3</mn>
                                                        </mfrac>
                                                    </mrow>
                                                    <annotation encoding="application/x-tex">\frac{-1}{3}</annotation>
                                                </semantics>
                                            </math>
                                        </span>
                                        <span className="katex-html" aria-hidden="true">
                                            <span className="base">
                                                <span className="strut" style={{ height: "1.1901em", verticalAlign: "-0.345em" }}></span>
                                                <span className="mord">
                                                    <span className="mopen nulldelimiter"></span>
                                                    <span className="mfrac">
                                                        <span className="vlist-t vlist-t2">
                                                            <span className="vlist-r">
                                                                <span className="vlist" style={{ height: "0.8451em" }}>
                                                                    <span style={{ top: "-2.655em" }}>
                                                                        <span className="pstrut" style={{ height: "3em" }}></span>
                                                                        <span className="sizing reset-size6 size3 mtight">
                                                                            <span className="mord mtight">
                                                                                <span className="mord mtight">3</span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <span style={{ top: "-3.23em" }}>
                                                                        <span className="pstrut" style={{ height: "3em" }}></span>
                                                                        <span className="frac-line" style={{ borderBottomWidth: "0.04em" }}></span>
                                                                    </span>
                                                                    <span style={{ top: "-3.394em" }}>
                                                                        <span className="pstrut" style={{ height: "3em" }}></span>
                                                                        <span className="sizing reset-size6 size3 mtight">
                                                                            <span className="mord mtight">
                                                                                <span className="mord mtight">−</span>
                                                                                <span className="mord mtight">1</span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <span className="vlist-s">​</span>
                                                            </span>
                                                            <span className="vlist-r">
                                                                <span className="vlist" style={{ height: "0.345em" }}>
                                                                    <span></span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <span className="mclose nulldelimiter"></span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </td>
                        <td>-1/3<br />-.3333<br />-0.333</td>
                        <td>-.33<br />-0 .33</td>
                    </tr>
                </tbody>
            </table>
        </div>

    )
}
