import React from 'react';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

function MathReview(props) {
    // Thêm dấu ngoặc kép quanh công thức LaTeX để nó được biên dịch đúng cách
    let latexString = `\\(\\)`;
    if (props.input) {


        latexString = `\\(${props.input.replace(/(\d+)\/(\d+)/g, '\\frac{$1}{$2}')}\\)`;
    }


    return (
        // Hiển thị Latex chỉ khi props.input có giá trị
        props.input ? <Latex>{latexString}</Latex> : null
    );
}

export default MathReview;
