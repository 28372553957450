import React, { useEffect, useState } from 'react';
import { fetchAllAttemptsAPI } from '../../apis'; // Ensure you have this API endpoint available
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import './AllAttempt.css'; // Import your CSS file

const AllAttempt = () => {
  const [attemptList, setAttemptList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleViewDetails = (attemptId) => {
    navigate(`/score?attemptId=${attemptId}`);
  };

  useEffect(() => {
    setLoading(true);
    const fetchAttemptData = async () => {
      try {
        const response = await fetchAllAttemptsAPI(); // Assuming this API fetches all attempts
        const data = response.data.result;
        setAttemptList(data);
      } catch (error) {
        console.error("Error fetching attempts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAttemptData();
    // eslint-disable-next-line 
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">All Attempts</h2>
      <table className="table table-striped table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">User</th>
            <th scope="col">Mock Name</th>
            <th scope="col">Deadline Time</th>
            <th scope="col">Score</th>
            <th scope="col">Status</th>
            <th scope="col">Type</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {attemptList.length > 0 ? (
            attemptList.map((attempt, index) => (
              <tr key={attempt.attemptId}>
                <th scope="row">{index + 1}</th>
                <td>{attempt.username}</td>
                <td>{attempt.mockName}</td>
                <td>{format(new Date(attempt.deadlineTime), 'dd-MM-yyyy, hh:mm a')}</td>
                <td>{attempt.score}</td>
                
                <td>
                  <span className={
                    attempt.status === 'FINISH' ? 'status-finish' :
                      attempt.status === 'IN_PROCESS' ? 'status-in-progress' :
                        'status-not-started'
                  }>
                    {attempt.status}
                  </span>
                </td>
                <td>{attempt.type}</td>
                <td>
                  <button
                    className="btn btn-info mx-1"
                    onClick={() => handleViewDetails(attempt.attemptId)}
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">No attempts found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllAttempt;
