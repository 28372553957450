// utils/updateAttempt.js
import { updateAttemptAPI } from './index';

export const updateAttempt = (attemptId, mock, time, status , score = 0.0) => {
    const attemptData = {
        choices: mock.choices,
        markedQuestions: mock.markedQuestions,
        module: mock.module,
        excludedChoices: mock.excludedChoices,
        menu: mock.menu,
        currentQuestion: mock.currentQuestion,
        startAtQuestions: mock.startAtQuestions,
        endAtQuestions: mock.endAtQuestions,
    };
    const jsonString = JSON.stringify(attemptData);
    const jsonFile = new Blob([jsonString], { type: 'application/json' });
    const formData = new FormData();
    formData.append('file', jsonFile, "file.json");
    formData.append('module', mock.module);
    if(status){
        formData.append('status', status);
    }
    formData.append('score', score);
   
    formData.append('timer', time);

    return updateAttemptAPI(attemptId, formData);
};
