import React from 'react';
import './Register.css';
import { useState } from 'react';

const Register = () => {
    // const [username, setUsername] = useState('');
    // const [email, setEmail] = useState('');
    // const [phone,setPhone] = useState('');

    // const [password, setPassword] = useState('');
    // const [confirmPassword, setConfirmPassword] = useState('');
    // const [message, setMessage] = useState('');
   
    // const [code, setCode] = useState();
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     try {
    //       const response = await axios.post('http://localhost:8080/sat-backend/auth/login', {
    //         username,
    //         password,
    //       });
    //       const code = response.data.code;
    //       const token = response.data.result.token;
    //       setCode(code);
    //       if(code === 1000){
    //         login(token);
    //         setMessage('Login successful.');
    //         navigate(-2); // chuyển về trang trước đó
    //         setTimeout(() => {
            
    //           window.location.reload(); // Reload trang
    //         }, 500);
    //       }else if (code === 1001){
    //         setMessage('Login failed. Username or password is incorrect.');
    //         }
    //     } catch (error) {
    //       setCode(error.response.data.code);
    //       setMessage('Login failed. Username or password is incorrect.');
    //     }finally{
    //       setLoading(false);
    //     }
    // }
   
    
    return (
        <div className="login-container d-flex justify-content-center align-items-center">
            <form className="register-form p-4">
                <h2 className="text-center mb-4">Register</h2>

                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Enter username"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter email"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter password"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        placeholder="Confirm password"
                        required
                    />
                </div>

                <button type="submit" className="btn btn-primary btn-block mt-4">
                    Register
                </button>

            </form>
        </div>
    );
};

export default Register;
