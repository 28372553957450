import { configureStore } from "@reduxjs/toolkit";
import mockReducer from "../slice/mockSlice";
import timerReducer from "../slice/timerSlice";
import userReducer from "../slice/userSlice";



// const saveTimerState = (store) => {
//     const state = store.getState();
//     if (state.timer.time % 10 === 0) {
//         localStorage.setItem('timer', JSON.stringify(state.timer));    
//     }
// };
// const saveMockState = (store) => {
//     const state = store.getState();
//         localStorage.setItem('mock', JSON.stringify(state.mock));
// };

// // Tải trạng thái timer từ localStorage
// const loadTimerState = () => {
//     try {
//         const serializedState = localStorage.getItem('timer');
//         if (serializedState === null) {
//             return undefined;
//         }
//         return JSON.parse(serializedState);
//     } catch (err) {
//         return undefined;
//     }
// };
// // Tải trạng thái mock từ localStorage
// const loadMockState = () => {
//     try {
//         const serializedState = localStorage.getItem('mock');
//         if (serializedState === null) {
//             return undefined;
//         }
//         return JSON.parse(serializedState);
//     } catch (err) {
//         return undefined;
//     }
// };
// // Sử dụng loadTimerState để khởi tạo state 
// const preloadedState = {
//     mock: loadMockState(),
//     timer: loadTimerState(),
// };



//---------------------------------- store---------------------
export const store = configureStore({
    reducer: {
        mock: mockReducer,
        timer: timerReducer,
        user: userReducer,
        },
    // preloadedState,

});
//---------------------------------- store--------------------------------
// Đăng ký saveTimerState để lưu state vào localStorage
// store.subscribe(() => {
//     saveTimerState(store);
//     saveMockState(store);
//     }
// );